<template>
    <header ref="header" class="sticky top-0 flex items-center justify-between w-full bg-white h-[60px] drop-shadow-2xl px-3 z-20 relative lg:px-6">
        <div class="flex flex-1 w-0 items-center -ml-2">
            <button type="button" class="p-2 mr-2 xl:hidden" @click="navMobileOpen = true">
                <span class="sr-only">{{ __('Menu') }}</span>
                <menu-icon class="h-6 w-6"/>
            </button>

            <router-link :to="{name: 'home', params: {lang: $store.getters.lang}}" class="p-2 relative">
                <logo/>
            </router-link>
        </div>

        <nav class="hidden xl:flex">
            <nav-desktop v-if="showNav" :nav="nav"/>
        </nav>

        <div class="flex flex-1 w-0 justify-end -mr-2 space-x-1">
            <search/>
            <tools-icon/>
            <fav-icon/>
            <cart-icon/>
        </div>
    </header>

    <nav-mobile :show="navMobileOpen" :nav="nav" @close="navMobileOpen = false"/>
</template>

<script>
import {MenuIcon} from "@heroicons/vue/outline";
import Logo from "@/components/Logo";
import NavMobile from "@/components/NavMobile";
import NavDesktop from "@/components/NavDesktop";
import CartIcon from "@/components/Header/CartIcon";
import Search from "@/components/Header/Search";
import ToolsIcon from "@/components/Header/ToolsIcon";
import FavIcon from "@/components/Header/FavIcon";

export default {
    name: "PageHeader",

    components: {
        ToolsIcon,
        FavIcon,
        Search,
        NavDesktop,
        NavMobile,
        Logo,
        MenuIcon,
        CartIcon
    },

    data() {
        return {
            navMobileOpen: false,
            nav: [],
            showNav: true
        }
    },

    created() {
        if (this.$store.getters.scope) {
            this.fetchData()
        }

        this.$emitter.on('scopeUpdated', () => {
            this.fetchData()
        })

        this.$emitter.on('localeUpdated', () => {
            this.fetchData()
        })

        this.$emitter.on('searchToggle', (event) => {
            this.showNav = event.open
        })
    },

    methods: {
        fetchData() {
            this.$api.get('/navigation').then(data => this.nav = data)
        },
    }
}
</script>
