<template>
    <div class="relative">
        <Menu>
            <MenuButton class="inline-flex items-center p-2 rounded-full hover:bg-zinc-100">
                <span class="sr-only">{{ __('Narzędzia') }}</span>
                <cog-icon class="h-6 w-6"/>
            </MenuButton>

            <transition enter-active-class="transition ease-out duration-100"
                        enter-from-class="transform opacity-0 scale-95"
                        enter-to-class="transform opacity-100 scale-100"
                        leave-active-class="transition ease-in duration-75"
                        leave-from-class="transform opacity-100 scale-100"
                        leave-to-class="transform opacity-0 scale-95">
                <MenuItems
                    class="origin-top-right absolute top-full mt-2 right-0 w-64 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="p-5 space-y-3">
                        <div class="flex items-center text-zinc-500">
                            <document-text-icon class="h-5 w-5 mr-2"/>
                            <span class="text-md">{{ __('Pliki XLS') }}</span>
                        </div>

                        <div class="ml-4" v-if="all_productlist_id">
                            <button type="button" class="flex items-center" @click="downloadXls">
                                <document-add-icon class="h-4 w-4 mr-2"/>
                                <span class="text-sm">{{ __('Eksport oferty do XLS') }}</span>
                            </button>
                        </div>
                        <div class="ml-4">
                            <button type="button" class="flex items-center" @click="toggleCartImportXlsModal(true)">
                                <document-add-icon class="h-4 w-4 mr-2"/>
                                <span class="text-sm">{{ __('Utwórz koszyk z pliku XLS') }}</span>
                            </button>
                        </div>

                        <template v-if="catalogues.length">
                            <div class="flex items-center text-zinc-500">
                                <document-icon class="h-5 w-5 mr-2"/>
                                <span class="text-md">{{ __('Dostępne katalogi') }}</span>
                            </div>

                            <div v-for="(catalogue, catalogueIdx) in catalogues" :key="catalogueIdx" class="ml-6">
                                <a :href="catalogue.link" class="flex items-center">
                                    <document-add-icon class="h-4 w-4 mr-2"/>
                                    <span class="text-sm">{{ catalogue.title }}</span>
                                </a>
                            </div>
                        </template>

                        <template v-if="walls.length">
                            <div class="flex items-center text-zinc-500">
                                <document-icon class="h-5 w-5 mr-2"/>
                                <span class="text-md">{{ __('Dostępne ścianki') }}</span>
                            </div>

                            <div v-for="(wall, wallIdx) in walls" :key="wallIdx" class="ml-6">
                                <a :href="wall.link" class="flex items-center">
                                    <document-add-icon class="h-4 w-4 mr-2"/>
                                    <span class="text-sm">{{ wall.title }}</span>
                                </a>
                            </div>
                        </template>
                    </div>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script>
import {DocumentTextIcon, CogIcon, DocumentIcon} from "@heroicons/vue/outline";
import {DocumentAddIcon} from "@heroicons/vue/solid";
import {Menu, MenuButton, MenuItems} from "@headlessui/vue";
import HasCartImportXlsModalMixin from "@/mixins/HasCartImportXlsModalMixin";

export default {
    name: "ToolsIcon",

    components: {
        Menu,
        MenuButton,
        MenuItems,
        CogIcon,
        DocumentTextIcon,
        DocumentAddIcon,
        DocumentIcon
    },

    mixins: [HasCartImportXlsModalMixin],

    data() {
        return {
            catalogues: [],
            walls: [],
            all_productlist_id: null,
        }
    },

    created() {
        this.fetchData()
    },

    methods: {
        fetchData() {
            let config = JSON.parse(localStorage.getItem('customer-config'));
            this.all_productlist_id = config.all_productlist_id;
            this.$api.get('/downloads').then(data => {
                this.catalogues = data.catalogues
                this.walls = data.walls
            })
        },
        downloadXls() {
            this.$api.post('/product/list/export/xls', {
                init: false,
                list_id: this.all_productlist_id,
                search_query: '',
                filters: {},
                page: 1,
                sort: "name",
            }, {
                responseType: 'blob',
            }).then(data => {
                let file = window.URL.createObjectURL(new Blob([data]));
                let link = document.createElement('a');
                link.href = file;
                link.setAttribute('download', 'export-offer-full.xls');
                document.body.appendChild(link);
                link.click();
            }).catch(error => {
                console.log(error)
                this.$toastr.error(this.__('Nie udało się pobrać pliku XML!'))
            })
        },
    }
}
</script>
