import axios from 'axios';

axios.defaults.baseURL = `${process.env.VUE_APP_SPA_URL}`
axios.defaults.headers.common['Accept'] = 'application/json'

let apiRequest = {
    get(url, options = {}) {
        return axios.get(url, options).then(response => response.data)
    },

    delete(url, options = {}) {
        return axios.delete(url, options).then(response => response.data)
    },

    async post(url, data = {}, options = {}) {
        return axios.post(url, data, options).then(response => response.data)
    },

    async put(url, data = {}, options = {}) {
        return axios.put(url, data, options).then(response => response.data)
    },

    setHeader(type, value) {
        axios.defaults.headers.common[`dvrs-${type}`] = value
    },
}

export function setAuthHeader(token) {
    if (token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    } else {
        delete axios.defaults.headers.common['Authorization']
    }
}

export { apiRequest }

export default {
    install: (app) => {
        app.config.globalProperties.$api = apiRequest
    }
}
