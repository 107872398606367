<template>
    <div class="flex items-center justify-center w-full" :class="[fullScreen ? 'h-screen w-screen' : 'py-20']">
        <logo class="animate-pulse scale-150"/>
    </div>
</template>

<script>
import Logo from "@/components/Logo";
export default {
    name: "Loader",
    components: {Logo},
    props: {
        fullScreen: {
            type: Boolean,
            default: false
        }
    }
}
</script>
