<template>
    <modal :show="show" :title="__('Pomoc')" modal-class="lg:max-w-lg" @submitted="submitForm" @close="show = false">
        <div class="mb-5">
            <div class="space-y-6 sm:space-y-5">
                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                    <label for="subject" class="block sm:mt-px sm:pt-2">{{ __('Temat') }}</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2 lg:col-span-2">
                        <base-input v-model="formHelp.subject" class="w-full"/>
                        <form-error :message="formHelp.errors.subject"/>
                    </div>
                </div>

                <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:pt-5">
                    <label for="message" class="block sm:mt-px sm:pt-2">{{ __('Wiadomość') }}</label>
                    <div class="mt-1 sm:mt-0 sm:col-span-2 lg:col-span-2">
                        <base-textarea :rowsValue="5" v-model="formHelp.message" class="w-full"/>
                        <form-error :message="formHelp.errors.message"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-center">
            <base-button type="submit" class="px-12" :disabled="processing">
                {{ __('Wyślij wiadomość') }}
            </base-button>
        </div>
    </modal>
</template>

<script>
import BaseInput from "@/components/BaseInput";
import BaseTextarea from "@/components/BaseTextarea";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import apiForm from "@/tools/form";
import FormError from "@/components/FormError";

export default {
    name: "HelpModal",

    components: {
        Modal,
        BaseInput,
        BaseTextarea,
        BaseButton,
        FormError
    },

    data() {
        return {
            formHelp: apiForm({
                subject: '',
                message: '',
            }),
            show: false,
            processing: false
        }
    },

    computed: {

    },

    created() {
        this.$emitter.on('toggleHelpModal', event => this.show = event)
    },

    methods: {
        submitForm() {
            this.processing = true
            this.formHelp.post('/customer/help',  {
                onSuccess: () => {
                    this.show = false
                    this.formHelp.reset()
                    this.processing = false
                    this.$toastr.success(this.__('Wiadomość została wysłana.'))
                },
                onError: (error) => {
                    this.processing = false
                    if (error.status != 422) {
                        this.$toastr.error(this.__('Nie udało się wysłać wiadomości! (błąd ' + error.status + ')'))
                    }
                }
            })
        }
    }
}
</script>
