import {apiRequest} from "@/tools/api";

export const fetchData = (url, callback) => {
    apiRequest.get(url)
        .then(data => callback(data, null))
        .catch(error => {
            if (error.response.status) {
                // window.location.href =
            }
            callback(null, error.response.status)
        })
};
