<template>
    <div  class="border-t border-zinc-200">
        <div class="flex items-center justify-between pl-2 py-2">
            <div class="flex">
                <button type="button" @click="$emit('setColor', color)">
                    <span class="block border border-gray rounded-full h-4 w-4 mr-2" :style="[color.color_hex2 !== '' ? {'background':'linear-gradient(120deg,'+color.color_hex+' 50%,'+color.color_hex2+' 50%)'} : {'background-color':color.color_hex}]" />
                </button>

                <div class="cursor-pointer" @click="$emit('setColor', color)">{{ color.name }}</div>

                <button type="button" v-show="color.open" class="items-center justify-center w-8 space-x-1"
                        :class="[inFav ? 'text-rose-600' : 'text-zinc-400']"
                        @click="onClickFav">
                    <solid-heart-icon v-if="inFav" class="h-5 w-5 inline-block" />
                    <heart-icon v-else class="h-5 w-5 inline-block" />
                </button>
              
                <button v-if="duplicate && cartQty > 0" type="button" @click="$emit('showDuplicateModal', color)" :title="__('Duplikuj')">
                    <duplicate-icon class="h-4 w-4 ml-2"/>
                </button>
            </div>

            <div class="text-right">
                <span class="block text-xs lg:inline">
                    {{ cartQty }} {{ __('sztuk') }}
                </span>

                <span class="hidden lg:inline">
                    &nbsp;/&nbsp;
                </span>

                <span class="block font-bold lg:inline">
                    {{ cartTotal }} <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
                </span>
            </div>
        </div>

        <div v-show="color.open" class="pl-8 pt-2">
            <table class="w-full">
                <thead>
                    <tr class="text-xs text-zinc-500">
                        <td class="w-1/3 lg:w-1/4">{{ __('Rozmiar') }}</td>
                        <td class="w-1/3 lg:w-1/4">{{ __('Ilość') }}</td>
                        <td class="hidden w-1/3 lg:w-1/4 lg:table-cell"><!--  --></td>
                        <td class="w-1/3 lg:w-1/4 text-right">{{ __('Cena') }}</td>
                    </tr>
                </thead>
                <tbody>
                    <slot/>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import {DuplicateIcon, HeartIcon} from "@heroicons/vue/outline"
import {HeartIcon as SolidHeartIcon} from "@heroicons/vue/solid";

export default {
    name: "ProductColorItem",

    components: {
        DuplicateIcon,
        HeartIcon,
        SolidHeartIcon
    },

    emits: [
        'setColor',
        'showDuplicateModal',
        'onClickFav'
    ],
    props: {
        color: {
            type: Object,
            required: true
        },
        duplicate: {
            type: Boolean,
            required: true
        },
        favProducts: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        cartQty() {
            let cartQty = 0
            this.color.variants.forEach(variant => cartQty += variant.cart_qty)

            return cartQty
        },
        inFav() {
            return this.favProducts.includes(this.color.id)
        },
        cartTotal() {
            let cartTotal = 0
            this.color.variants.forEach(variant => cartTotal += (variant.cart_qty * variant.price))

            return this.price(cartTotal)
        },

        displayCurrency() {
            return !!this.$store.getters.customerConfig.display_currency;
        }
    },
    methods: {
        onClickFav() {
            this.$emit('onClickFav', this.color)
        },
    }
}
</script>