<template>
    <footer class="bg-zinc-900">
        <div class="container mx-auto py-3 lg:py-5">
            <div class="grid gap-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6">
                <div v-for="(item, itemIdx) in nav" :key="itemIdx">
                    <div class="text-white mb-3">
                        {{ item.title }}
                    </div>

                    <div class="text-sm text-zinc-500">
                        <div v-if="itemIdx === 0" class="mb-4">
                            <template v-if="customerConfig.sale_office_code === 'PS'">
                                PN-PT 8:00 - 16:00
                                <br><a href="tel:+48781731777" class="hover:text-zinc-400">+48 781 731 777</a>
                                <br><a href="tel:+48609225038" class="hover:text-zinc-400">+48 609 225 038</a>
                                <br><a href="mailto:Projekty.specjalne@etos.pl" class="hover:text-zinc-400">Projekty.specjalne@etos.pl</a>
                            </template>
                            <template v-else>
                                <a href="mailto:b2b@etos.pl" class="hover:text-zinc-400">b2b@etos.pl</a>
                            </template>
                        </div>

                        <div v-for="(subItem, subItemIdx) in item.children" :key="subItemIdx">
                            <router-link
                                :to="{name: 'category', params: {lang: $store.getters.lang, id: subItem.id, slug: subItem.slug}}"
                                class="hover:text-zinc-400">
                                {{ subItem.title }}
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="md:col-span-3 lg:col-start-4 xl:col-start-6 text-right">
                    <div class="hidden text-white mb-3 lg:block">
                        Social Media
                    </div>

                    <div class="flex justify-evenly lg:flex-col lg:space-y-2">
                        <a href="https://www.facebook.com/DiverseSystem"
                           class="flex flex-col items-center text-zinc-500 hover:text-zinc-400 lg:flex-row-reverse">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M29.24 7.65C29.24 3.44 25.8 0 21.59 0H7.65C3.44 0 0 3.44 0 7.65v13.94c0 4.21 3.44 7.65 7.65 7.65h4.69v-9.91H8.62V15.1h3.71v-3.22c0-3.66 2.18-5.69 5.52-5.69 1.6 0 3.27.29 3.27.29v3.6h-1.84c-1.82 0-2.38 1.13-2.38 2.28v2.74h4.06l-.65 4.23H16.9v9.91h4.69c4.21 0 7.65-3.44 7.65-7.65V7.65Z"
                                    fill="currentcolor"/>
                            </svg>
                            <span class="text-sm mt-1 lg:mt-0 lg:mr-2">Facebook</span>
                        </a>

                        <a href="https://instagram.com/diversesystem"
                           class="flex flex-col items-center text-zinc-500 hover:text-zinc-400 lg:flex-row-reverse">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.357 11.92c-1.49 0-2.7 1.21-2.7 2.7 0 1.49 1.21 2.7 2.7 2.7 1.49 0 2.7-1.21 2.7-2.7 0-1.49-1.21-2.7-2.7-2.7Z"
                                    fill="currentcolor"/>
                                <path
                                    d="M18.707 7.66h-6.84c-1.95 0-3.54 1.59-3.54 3.54v6.84c0 1.95 1.59 3.54 3.54 3.54h6.84c1.95 0 3.54-1.59 3.54-3.54V11.2c0-1.95-1.59-3.54-3.54-3.54Zm-3.35 11.18a4.22 4.22 0 1 1 .001-8.441 4.22 4.22 0 0 1-.001 8.441Zm4.47-7.72c-.55 0-1.01-.45-1.01-1.01 0-.55.45-1 1.01-1s1.01.45 1.01 1a1.02 1.02 0 0 1-1.01 1.01Z"
                                    fill="currentcolor"/>
                                <path
                                    d="M22.257 0H8.317C4.107 0 .667 3.44.667 7.65v13.94c0 4.21 3.44 7.65 7.65 7.65h13.94c4.21 0 7.65-3.44 7.65-7.65V7.65c0-4.21-3.44-7.65-7.65-7.65Zm1.6 18.04c0 2.84-2.31 5.15-5.15 5.15h-6.84c-2.84 0-5.15-2.31-5.15-5.15V11.2c0-2.84 2.31-5.15 5.15-5.15h6.84c2.84 0 5.15 2.31 5.15 5.15v6.84Z"
                                    fill="currentcolor"/>
                            </svg>
                            <span class="text-sm mt-1 lg:mt-0 lg:mr-2">Instagram</span>
                        </a>

                        <a href="https://www.youtube.com/user/DiverseBrand"
                           class="flex flex-col items-center text-zinc-500 hover:text-zinc-400 lg:flex-row-reverse">
                            <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M21.923 0H7.983C3.773 0 .333 3.44.333 7.65v13.94c0 4.21 3.44 7.65 7.65 7.65h13.94c4.21 0 7.65-3.44 7.65-7.65V7.65c0-4.2-3.44-7.65-7.65-7.65Zm.86 16.68c0 1.94-1.57 3.51-3.51 3.51h-8.98c-1.94 0-3.51-1.57-3.51-3.51V12.5c0-1.94 1.57-3.51 3.51-3.51h8.98c1.94 0 3.51 1.57 3.51 3.51v4.18Z"
                                    fill="currentcolor"/>
                                <path
                                    d="m17.333 14.49-3.85-2.1c-.16-.09-.7.02-.7.21v4.09c0 .18.54.29.69.21l4.03-1.99c.16-.1-.01-.33-.17-.42Z"
                                    fill="currentcolor"/>
                            </svg>
                            <span class="text-sm mt-1 lg:mt-0 lg:mr-2">YouTube</span>
                        </a>

                        <a href="https://www.pinterest.com/diversebrand/"
                           class="flex flex-col items-center text-zinc-500 hover:text-zinc-400 lg:flex-row-reverse">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M29.25 7.65C29.25 3.44 25.81 0 21.6 0H7.64999C3.43999 0 0 3.44 0 7.65V21.59C0 25.8 3.43999 29.24 7.64999 29.24H21.59C25.8 29.24 29.24 25.8 29.24 21.59L29.25 7.65ZM19.91 17.14C18.09 19.39 15.15 19.54 13.79 18.16C13.69 18.05 13.6 17.93 13.5 17.8C13.48 17.85 13.47 17.89 13.46 17.94C12.81 20.49 12.74 21.06 12.07 22.25C11.75 22.81 11.39 23.35 10.99 23.86C10.94 23.92 10.9 23.99 10.81 23.97C10.71 23.95 10.7 23.86 10.69 23.78C10.58 23 10.52 22.23 10.55 21.44C10.58 20.42 10.71 20.07 12.03 14.54C12.05 14.46 12.03 14.39 12 14.31C11.69 13.46 11.62 12.6 11.9 11.72C12.5 9.83 14.63 9.69 15.01 11.25C15.24 12.21 14.63 13.47 14.16 15.34C13.77 16.88 15.58 17.97 17.13 16.85C18.56 15.82 19.11 13.34 19 11.58C18.79 8.08 14.95 7.32 12.51 8.45C9.71999 9.74 9.08 13.21 10.34 14.79C10.5 14.99 10.62 15.11 10.57 15.32C10.49 15.64 10.42 15.96 10.33 16.27C10.26 16.5 10.07 16.59 9.82999 16.49C9.35999 16.3 8.98 16.01 8.66 15.62C7.59 14.29 7.28 11.67 8.7 9.45C10.27 6.99 13.19 5.99 15.85 6.29C19.03 6.65 21.05 8.83 21.42 11.3C21.61 12.41 21.48 15.18 19.91 17.14Z"
                                    fill="currentcolor"/>
                            </svg>
                            <span class="text-sm mt-1 lg:mt-0 lg:mr-2">Pinterest</span>
                        </a>
                    </div>
                </div>
            </div>
            <div class="text-right pt-2">
                <p class="text-xs text-zinc-500">{{ __('Wersja') }} {{ appVersion }}</p>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: "PageFooter",

    data() {
        return {
            nav: []
        }
    },

    computed: {
        appVersion() {
            return process.env.VUE_APP_VERSION
        },

        customerConfig() {
            return this.$store.getters.customerConfig
        }
    },

    created() {
        if (this.$store.getters.scope) {
            this.fetchData()
        }

        this.$emitter.on('scopeUpdated', () => {
            this.fetchData()
        })

        this.$emitter.on('localeUpdated', () => {
            this.fetchData()
        })
    },

    methods: {
        fetchData() {
            this.$api.get('/navigation/bottom').then(data => this.nav = data)
        },
    }
}
</script>
