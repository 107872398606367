import { cloneDeep } from 'lodash-es'
import { reactive } from 'vue'
import { apiRequest } from "@/tools/api"

export default function apiForm(...args) {
    const data = (typeof args[0] === 'string' ? args[1] : args[0]) || {}
    let defaults = cloneDeep(data)
    let recentlySuccessfulTimeoutId = null
    let transform = data => data

    let form = reactive({
        ...data,
        errors: {},
        hasErrors: false,
        processing: false,
        wasSuccessful: false,
        recentlySuccessful: false,
        data() {
            return Object
                .keys(data)
                .reduce((carry, key) => {
                    carry[key] = this[key]
                    return carry
                }, {})
        },
        transform(callback) {
            transform = callback

            return this
        },
        reset(...fields) {
            let clonedDefaults = cloneDeep(defaults)
            if (fields.length === 0) {
                Object.assign(this, clonedDefaults)
            } else {
                Object.assign(
                    this,
                    Object
                        .keys(clonedDefaults)
                        .filter(key => fields.includes(key))
                        .reduce((carry, key) => {
                            carry[key] = clonedDefaults[key]
                            return carry
                        }, {}),
                )
            }

            return this
        },
        setValues(values) {
            Object.keys(data).forEach(key => {
                if (values[key]) {
                    this[key] = values[key]
                }
            })
        },
        hasError(field) {
            return this.errors[field] !== undefined
        },
        clearErrors(...fields) {
            this.errors = Object
                .keys(this.errors)
                .reduce((carry, field) => ({
                    ...carry,
                    ...(fields.length > 0 && !fields.includes(field) ? { [field] : this.errors[field] } : {}),
                }), {})

            this.hasErrors = Object.keys(this.errors).length > 0

            return this
        },
        submit(method, url, options = {}) {
            const data = transform(this.data())

            this.processing = true
            this.wasSuccessful = false
            this.recentlySuccessful = false
            clearTimeout(recentlySuccessfulTimeoutId)

            if (options.onBefore) {
                return options.onBefore()
            }

            apiRequest[method](url, data)
                .then(data => {
                    this.clearErrors()
                    this.wasSuccessful = true
                    this.recentlySuccessful = true
                    recentlySuccessfulTimeoutId = setTimeout(() => this.recentlySuccessful = false, 2000)

                    if (options.onSuccess) {
                        options.onSuccess(data)
                    }

                    defaults = cloneDeep(this.data())
                })
                .catch(error => {
                    this.errors = error.response.data.errors
                    this.hasErrors = true

                    if (options.onError) {
                        return options.onError(error.response)
                    }
                })
                .finally(() => {
                    form.processing = false

                    if (options.onFinish) {
                        options.onFinish()
                    }
                })
        },
        post(url, options) {
            this.submit('post', url, options)
        },
        put(url, options) {
            this.submit('put', url, options)
        }
    })

    return form
}
