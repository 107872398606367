<template>
    <modal :show="updateExists" :title-bar="false" modal-class="sm:max-w-md">
        <p class="text-center">
            {{ __('Dostępna aktualizacja') }}
        </p>

        <div class="space-x-4 text-center mt-3">
            <base-button size="sm" @click="refreshApp">
                {{ __('Aktualizuj') }}
            </base-button>
        </div>
    </modal>
</template>

<script>
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
export default {
    name: "ServiceWorkerUpdate",

    components: {
        Modal,
        BaseButton
    },

    data() {
        return {
            refreshing: false,
            registration: null,
            updateExists: false,
        };
    },

    created () {
        if ('serviceWorker' in navigator) {
            document.addEventListener(
                'swUpdated', this.showRefreshUI, {once: true}
            );

            navigator.serviceWorker.addEventListener('controllerchange', () => {
                if (this.refreshing) {
                    return;
                }
                this.refreshing = true;
                window.location.reload();
            });
        }
    },

    methods: {
        showRefreshUI(e) {
            this.registration = e.detail;
            this.updateExists = true;
        },

        refreshApp () {
            this.updateExists = false;
            if (!this.registration || !this.registration.waiting) {
                return;
            }
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        }
    }
}
</script>
