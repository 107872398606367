import {createStore} from 'vuex'
import {setAuthHeader} from "@/tools/api";

export default createStore({
    state: {
        uuid : localStorage.getItem('uuid') || null,
        scope: localStorage.getItem('scope') || null,
        locale: localStorage.getItem('locale') || process.env.VUE_APP_DEFAULT_LOCALE,
        currency: null,
        auth_token: localStorage.getItem('token') || null,
        customer_config: JSON.parse(localStorage.getItem('customer-config') || '{}'),
        sort: JSON.parse(localStorage.getItem('sort') || '{}'),
        filters: JSON.parse(localStorage.getItem('filters') || '{}'),
        filters_visibility: JSON.parse(localStorage.getItem('filters-visibility') || '{}'),
    },
    mutations: {
        uuid: (state, payload) => {
            state.uuid = payload;
        },
        scope: (state, payload) => {
            state.scope = payload;
        },
        locale: (state, payload) => {
            state.locale = payload;
        },
        currency: (state, payload) => {
            state.currency = payload;
        },
        search_prev_url: (state, payload) => {
            state.search_prev_url = payload;
        },
        auth_token(state, payload) {
            state.auth_token = payload
        },
        auth_logout(state) {
            state.auth_token = null
        },
        customer_config(state, payload) {
            state.customer_config = payload
        },
        sort(state, payload) {
            state.sort = payload
        },
        filters(state, payload) {
            state.filters = payload
        },
        filters_visibility(state, payload) {
            state.filters_visibility = payload
        }
    },
    actions: {
        setUuid({ commit }, data) {
            commit('uuid', data);
            localStorage.setItem('uuid', data);
        },
        setSearchPrevUrl({ commit }, data) {
            commit('search_prev_url', data);
            localStorage.setItem('uuid', data);
        },
        deleteScope({ commit }) {
            commit('scope', null);
            localStorage.removeItem('scope');
        },
        setScope({ commit }, data) {
            commit('scope', data);
            localStorage.setItem('scope', data);
        },
        setLocale({ commit }, data) {
            commit('locale', data);
            localStorage.setItem('locale', data);
        },
        setCurrency({ commit }, data) {
            commit('currency', data);
        },
        setAuth({ dispatch }, data) {
            if (!data) {
                dispatch('authLogout');
            }
        },
        authLogin({ commit }, data) {
            localStorage.removeItem('filters')
            localStorage.setItem('token', data.auth.token)
            localStorage.setItem('customer-config', JSON.stringify(data.config))
            setAuthHeader(data.auth.token)
            commit('filters', {})
            commit('auth_token', data.auth.token)
            commit('customer_config', data.config)
        },
        authLogout({ commit }) {
            localStorage.removeItem('token')
            setAuthHeader(null)
            commit('auth_logout')
        },
        setSort({ commit }, data) {
            localStorage.setItem('sort', JSON.stringify(data))
            commit('sort', data)
        },
        setFilters({ commit }, data) {
            localStorage.setItem('filters', JSON.stringify(data))
            commit('filters', data)
        },
        setFiltersVisibility({ commit }, data) {
            localStorage.setItem('filters-visibility', JSON.stringify(data))
            commit('filters_visibility', data)
        },
    },
    getters: {
        uuid: state => state.uuid,
        scope: state => state.scope,
        locale: state => state.locale,
        lang: state => state.locale,
        currency: state => state.currency,
        search_prev_url: state => state.search_prev_url,
        isLogged: state => !!state.auth_token,
        token: state => state.auth_token,
        sort: state => state.sort,
        filters: state => state.filters,
        filtersVisibility: state => state.filters_visibility,
        customerConfig: state => state.customer_config
    }
})
