<template>
    <div v-for="(item, itemIdx) in nav" :key="itemIdx">
        <nav-desktop-dropdown v-if="item.children.length" :element="item"/>

        <router-link v-else
                     :to="{name: 'category', params: {lang: $store.getters.lang, id: item.id, slug: item.slug}}"
                     class="text-xl p-4 hover:text-zinc-600">
            {{ item.title }}
        </router-link>
    </div>
</template>

<script>
import NavDesktopDropdown from "@/components/NavDesktopDropdown";

export default {
    name: "NavDesktop",

    components: {
        NavDesktopDropdown
    },

    props: {
        nav: {
            type: Array,
            required: true
        }
    }
}
</script>
