<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="fixed inset-x-0 top-10 bottom-0 flex z-40 xl:hidden" @close="$emit('close')">
            <TransitionChild as="template"
                             enter="transition ease-in-out duration-300 transform"
                             enter-from="translate-x-full"
                             enter-to="translate-x-0"
                             leave="transition ease-in-out duration-300 transform"
                             leave-from="translate-x-0"
                             leave-to="translate-x-full">
                <div class="relative w-screen">
                    <button type="button" class="absolute p-4 top-0 right-0 z-10 bg-white" @click="$emit('close')">
                        <x-icon class="h-6 w-6"/>
                    </button>

                    <nav-mobile-pane
                        :nav="navCurrent"
                        :handle-click-next="handleClickNext"
                        :handle-click-prev="handleClickPrev"
                        @close="$emit('close')"
                    />

                    <TransitionRoot as="template"
                                    :show="transitionPrev"
                                    enter="transition duration-300 transform"
                                    enter-from="-translate-x-full"
                                    enter-to="translate-x-0">
                        <nav-mobile-pane :nav="navPrev"/>
                    </TransitionRoot>

                    <TransitionRoot as="template"
                                    :show="transitionNext"
                                    enter="transition duration-300 transform"
                                    enter-from="translate-x-full"
                                    enter-to="translate-x-0">
                        <nav-mobile-pane :nav="navNext"/>
                    </TransitionRoot>
                </div>
            </TransitionChild>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {XIcon} from "@heroicons/vue/outline"
import {Dialog, TransitionChild, TransitionRoot} from '@headlessui/vue'
import NavMobilePane from "@/components/NavMobilePane";

export default {
    name: "NavMobile",

    components: {
        XIcon,
        NavMobilePane,
        Dialog,
        TransitionChild,
        TransitionRoot,
    },

    emits: ['close'],

    props: {
        show: {
            type: Boolean,
            default: false
        },
        nav: {
            type: Array,
            default: () => []
        }
    },

    data() {
        return {
            navStack: [],
            navCurrent: {backTitle: '', navTitle: '', isNested: false, items: []},
            navNext: {backTitle: '', navTitle: '', isNested: false, items: []},
            navPrev: {backTitle: '', navTitle: '', isNested: false, items: []},
            transitionNext: false,
            transitionPrev: false,
        }
    },

    computed: {
        isNested() {
            return this.navStack.length > 0
        }
    },

    watch: {
        nav(value) {
            this.navCurrent.items = value
        }
    },

    methods: {
        handleClickNext(item) {
            this.navNext = {
                backTitle: this.isNested ? this.navCurrent.navTitle : 'Menu',
                navTitle: item.title,
                isNested: true,
                items: item.children
            }
            this.transitionNext = true
            setTimeout(() => {
                this.navStack.push(Object.assign({}, this.navCurrent))
                this.navCurrent = Object.assign({}, this.navNext)
                this.transitionNext = false
            }, 300);
        },

        handleClickPrev() {
            if (this.navStack.length) {
                this.navPrev = this.navStack[this.navStack.length - 1]
                this.transitionPrev = true

                setTimeout(() => {
                    this.navCurrent = this.navStack[this.navStack.length - 1]
                    this.navStack.pop()
                    this.transitionPrev = false
                }, 300);
            }
        }
    }
}
</script>
