<template>
    <input :type="type"
           :value="modelValue"
           :placeholder="placeholder"
           :disabled="disabled"
           :class="{'cursor-not-allowed bg-zinc-50': disabled}"
           class="appearance-none rounded-md p-2 border border-zinc-400"
           @input="$emit('update:modelValue', $event.target.value)"/>
</template>

<script>
export default {
    name: "BaseInput",

    props: {
        type: {
            type: String,
            default: 'text'
        },
        modelValue: {
            type: [String, Number, null],
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },

    emits: ['update:modelValue']
}
</script>
