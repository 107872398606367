<template>
    <div @mouseenter="showDropdown = true" @mouseleave="showDropdown = false">
        <router-link :to="{name: 'category', params: {lang: $store.getters.lang, id: element.id, slug: element.slug}}"
                     class="text-xl p-4 hover:text-zinc-600"
                     @click="showDropdown = false">
            {{ element.title }}
        </router-link>

        <transition enter-active-class="transition ease-out duration-200"
                    enter-from-class="opacity-0 -translate-y-1"
                    enter-to-class="opacity-100 translate-y-0"
                    leave-active-class="transition ease-in duration-150"
                    leave-from-class="opacity-100 translate-y-0"
                    leave-to-class="opacity-0 -translate-y-1">
            <div v-if="showDropdown" class="absolute z-20 top-full inset-x-0 -mt-px transform bg-white">
                <div class="flex justify-center space-x-10">
                    <div v-for="(subItem, subItemIdx) in element.children" :key="subItemIdx" class="py-5">
                        <div class="text-lg mb-3">
                            {{ subItem.title }}
                        </div>

                        <ul class="space-y-1" :class="{'columns-2': subItem.has_nav_cols}">
                            <li v-for="(subSubItem, subSubItemIdx) in subItem.children"
                                :key="subSubItemIdx"
                                class="w-40">
                                <router-link
                                    :to="{name: 'category', params: {lang: $store.getters.lang, id: subSubItem.id, slug: subSubItem.slug}}"
                                    class="text-zinc-500 hover:text-zinc-400"
                                    @click="showDropdown = false">
                                    {{ subSubItem.title }}
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "NavDesktopDropdown",

    props: {
        element: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            showDropdown: false
        }
    }
}
</script>
