<template>
    <modal :show="show" modal-class="xl:max-w-6xl" @close="$emit('close')">
        <div class="grid lg:grid-cols-2 gap-10">
            <div class="w-full aspect-[2/3] overflow-hidden">
                <v-lazy-image :src="product.photos.length ? product.photos[0].list_url : '/assets/blank.png'"
                              :alt="product.name"
                              src-placeholder="/assets/blank.png"
                              class="w-full h-full object-center object-cover"/>
            </div>

            <div>
                <div class="text-lg">{{ product.code_name }}</div>
                <div class="text-xs text-zinc-500 mt-1">
                    {{ product.generic }}
                </div>

                <product-colors :colors="colors"/>
            </div>
        </div>
    </modal>
</template>

<script>
import Modal from "@/components/Modal";
import ProductColors from "@/views/Product/components/ProductColors";
import VLazyImage from "v-lazy-image";

export default {
    name: "ProductQuickBuyModal",

    components: {
        ProductColors,
        Modal,
        VLazyImage,
    },

    emits: ['close'],

    props: {
        show: {
            type: Boolean,
            default: false
        },
        product: {
            type: Object,
            required: true
        },
        colors: {
            type: Array,
            required: true
        }
    }
}
</script>
