<template>
    <label class="inline-flex items-start">
        <input
            v-model="proxyModel"
            type="checkbox"
            class="border-2 rounded shadow-sm"
            :class="[hasError ? 'border-red-400 text-red-400' : 'border-zinc-900 text-zinc-900']"
            :value="value"
            :disabled="disabled">
        <span class="ml-2 text-sm" :class="{'text-red-400': hasError}">
            <span v-if="label">{{ label }}</span>
            <span v-else><slot/></span>
        </span>
    </label>
</template>

<script>
export default {
    name: "BaseCheckbox",

    props: {
        modelValue: {
            type: [Array, Boolean, Number, String],
            default: null
        },
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [Array, Boolean, Number, String],
            default: null,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        hasError: {
            type: [Boolean, String],
            default: false
        }
    },

    emits: [
        'update:modelValue'
    ],

    computed: {
        proxyModel: {
            get() {
                return this.modelValue;
            },
            set(val) {
                if (this.value) {
                    this.$emit('update:modelValue', val);
                } else {
                    this.$emit('update:modelValue', val === true);
                }
            }
        }
    }
}
</script>
