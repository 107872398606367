<template>
    <TransitionRoot as="template" :show="show">
        <Dialog as="div" class="fixed z-20 inset-0 overflow-y-auto" @close="$emit('close')">
            <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center block p-0">
                <TransitionChild as="template"
                                 enter="ease-out duration-300"
                                 enter-from="opacity-0"
                                 enter-to="opacity-100"
                                 leave="ease-in duration-200"
                                 leave-from="opacity-100"
                                 leave-to="opacity-0">
                    <DialogOverlay class="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

                <TransitionChild as="template"
                                 enter="ease-out duration-300"
                                 enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                 enter-to="opacity-100 translate-y-0 sm:scale-100"
                                 leave="ease-in duration-200"
                                 leave-from="opacity-100 translate-y-0 sm:scale-100"
                                 leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                    <div class="relative inline-block align-middle bg-white rounded-lg p-5 text-left overflow-hidden shadow-xl transform transition-all w-full m-4 sm:m-8" :class="modalClass">
                        <form @submit.prevent="$emit('submitted')">
                            <div v-if="titleBar" class="flex items-center justify-between mb-4">
                                <div class="font-bold">
                                    {{ title }}
                                </div>

                                <button type="button" @click="$emit('close')">
                                    <x-icon class="h-5 w-5"/>
                                </button>
                            </div>

                            <slot/>
                        </form>
                    </div>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import {XIcon} from "@heroicons/vue/outline";
import {TransitionRoot, TransitionChild, Dialog, DialogOverlay} from '@headlessui/vue'

export default {
    name: "Modal",

    components: {
        XIcon,
        TransitionRoot,
        TransitionChild,
        Dialog,
        DialogOverlay,
    },

    emits: [
        'close',
        'submitted'
    ],

    props: {
        show: {
            type: Boolean,
            default: false
        },

        title: {
            type: String,
            default: ''
        },

        titleBar: {
            type: Boolean,
            default: true
        },

        modalClass: {
            type: String,
            default: ''
        }
    },
}
</script>
