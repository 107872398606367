<template>
    <div class="bg-white flex absolute inset-0">
        <div class="flex items-center lg:w-1/2">
            <div class="p-10">
                <p class="text-sm font-medium text-red-600 uppercase tracking-wide">{{ __('Błąd :errorCode', {errorCode: code}) }}</p>
                <h1 class="mt-2 text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">{{ message.title }}</h1>
                <p class="mt-2 text-base text-gray-500">{{ message.description }}</p>
                <div class="mt-6">
                    <router-link :to="{name: 'home', params: {lang: $store.getters.lang}}"
                                 class="text-base font-medium text-red-600 hover:text-red-500">
                        {{ __('Powrót do strony głównej') }}
                        <span aria-hidden="true"> &rarr;</span>
                    </router-link>
                </div>
            </div>
        </div>

        <div class="w-1/2 relative hidden lg:block">
            <img class="absolute inset-0 h-full w-full object-cover"
                 src="https://diversesystem.com/uploads/error-404-athletics-copy1.jpg"
                 alt="">
        </div>

    </div>
</template>

<script>
export default {
    name: "Error",

    props: {
        code: {
            type: [Number, String],
            default: 404
        },
        fullScreen: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        message() {
            switch (this.code) {
                case 403:
                    return {
                        title: this.__('Brak dostępu'),
                        description: this.__('Zaloguj się, aby uzyskać dostęp do strony..'),
                        links: false,
                    }

                case 404:
                    return {
                        title: this.__('Nie znaleziono strony'),
                        description: this.__('Sprawdź adres URL w pasku adresu i spróbuj ponownie.'),
                        links: true,
                    }

                default:
                    return {
                        title: this.__('Wystąpił nieoczekiwany błąd'),
                        description: this.__('Jeżeli błąd będzie się powtarzał, skontaktuj się z właścicielem sklepu.'),
                        links: true,
                    }
            }
        }
    }
}
</script>
