export default {
    data() {
        return {
            quickBuyModal: false,
            quickBuyProduct: {},
            quickBuyColors: [],
        }
    },

    methods: {
        showQuickBuyModal(product) {
            this.quickBuyModal = true
            this.quickBuyProduct = product
            this.quickBuyColors = []

            this.$api.get(`/product/${product.id}`)
                .then(data => {
                    this.quickBuyColors = data.colors
                })
        },

        hideQuickBuyModal() {
            this.fetchInCart()
            this.quickBuyModal = false
        },

        fetchInCart() {
            //
        }
    }
}
