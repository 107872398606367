<template>
    <router-link :to="{name: 'cart.show', params: {lang: $store.getters.lang}}"
                 class="flex items-center rounded-full group"
                 :class="{'border border-zinc-100': cart.value > 0}">
        <span class="sr-only">{{ __('Koszyk') }}</span>
        <div class="p-2 rounded-full group-hover:bg-zinc-100">
            <shopping-cart-icon class="h-6 w-6"/>
        </div>
        <span v-if="cart.value > 0" class="hidden sm:inline-block px-2 text-xs">{{ price(cart.value) }}</span>
    </router-link>
</template>

<script>
import {ShoppingCartIcon} from "@heroicons/vue/outline";

export default {
    name: "CartIcon",

    components: {
        ShoppingCartIcon,
    },

    data() {
        return {
            cart: {}
        }
    },

    created() {
        if (this.$store.getters.scope) {
            this.fetchData()
        }

        this.$emitter.on('scopeUpdated', () => {
            this.fetchData()
        })

        this.$emitter.on('cartUpdated', () => {
            this.fetchData()
        })
    },

    methods: {
        fetchData() {
            this.$api.get('/cart/summary').then(data => {
                this.cart = data.cart
                this.$emitter.emit('cartSummaryLoaded')
            })
        },
    }
}
</script>
