<template>
    <div class="flex items-stretch">
        <button type="button"
                class="bg-zinc-100 text-zinc-400 rounded-tl-md rounded-bl-md px-2"
                :class="[cartQty <= 0 ? 'cursor-not-allowed' : 'hover:bg-zinc-200 hover:text-zinc-500']"
                :disabled="cartQty <= 0"
                @click="qtyMinus">
            <minus-icon class="h-4 w-4"/>
        </button>
        <input v-model="cartQty"
               type="text"
               class="border border-zinc-100 text-center max-w-[64px] py-1">
        <button type="button"
                class="bg-zinc-100 text-zinc-400 rounded-tr-md rounded-br-md px-2 hover:bg-zinc-200 hover:text-zinc-500"
                :class="[cartQty >= availableStock ? 'cursor-not-allowed' : 'hover:bg-zinc-200 hover:text-zinc-500']"
                :disabled="cartQty >= availableStock"
                @click="qtyPlus">
            <plus-icon class="h-4 w-4"/>
        </button>
    </div>
</template>

<script>
import {PlusIcon, MinusIcon} from "@heroicons/vue/outline";

export default {
    name: "CartItemQty",

    components: {
        PlusIcon,
        MinusIcon,
    },

    props: {
        modelValue: {
            type: [String, Number],
            default: 0
        },
        availableStock: {
            type: Number,
            required: true
        }
    },

    computed: {
        scopeCurrent() {
            return this.$store.getters.scope === 'current'
        },

        cartQty: {
            get() {
                return this.modelValue;
            },

            set(val) {
                let pVal = parseInt(val)

                if (pVal > this.availableStock && this.scopeCurrent) {
                    pVal = this.availableStock
                }

                this.$emit('update:modelValue', isNaN(pVal) ? 0 : pVal);
            }
        },
    },

    methods: {
        qtyPlus() {
            if (this.cartQty < this.availableStock || this.scopeCurrent === false) {
                this.cartQty += 1
            }
        },

        qtyMinus() {
            if (this.cartQty > 0) {
                this.cartQty -= 1
            }
        },
    }
}
</script>
