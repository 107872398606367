export default {
    data() {
        return {
            error: null,
        }
    },

    methods: {
        setData(data, error) {
            if (error) {
                this.handleError(error)
            }
        },

        handleError(error) {
            if (error === 401) {
                return this.redirectUnauthenticated()
            } else {
                this.error = error
            }
        },

        redirectUnauthenticated() {
            this.$store.dispatch('authLogout');

            return this.$router.replace({name: 'auth.login', params: {lang: this.$store.getters.lang}})
        }
    }
}
