export default {
    methods: {
        switchFavourite(product) {
            let favList = JSON.parse(sessionStorage.getItem('favlist-' + this.$store.getters.scope))
            if (favList === null) {
                favList = []
            }
            if (favList.includes(product.id)) {
                const index = favList.indexOf(product.id);
                favList.splice(index, 1);
                product.inFav = false
            } else {
                favList.push(product.id)
                product.inFav = true
            }
            sessionStorage.setItem('favlist-' + this.$store.getters.scope, JSON.stringify(favList))
            this.$emitter.emit('favUpdated')
            this.fetchFav()
        },
    }
}