<template>
    <loader v-if="status === 'loading'" :full-screen="true"/>

    <div v-else-if="status === 'success'" class="flex flex-col min-h-screen">
        <template v-if="$store.getters.isLogged">
            <section-header/>
            <page-header/>
            <main class="flex-grow relative">
                <router-view/>
            </main>
            <page-footer />
            <cart-import-xls-modal/>
            <help-modal/>
        </template>

        <router-view v-else/>
    </div>

    <error v-else/>

    <service-worker-update/>
</template>

<script>
import Loader from "@/components/Loader";
import Error from "@/views/Error";
import SectionHeader from "@/components/SectionHeader";
import PageHeader from "@/components/PageHeader";
import PageFooter from "@/components/PageFooter";
import {setAuthHeader} from "@/tools/api";
import ServiceWorkerUpdate from "@/components/ServiceWorkerUpdate";
import CartImportXlsModal from "@/views/Cart/components/CartImportXlsModal";
import HelpModal from "@/views/Content/components/HelpModal";

export default {
    components: {
        CartImportXlsModal,
        HelpModal,
        ServiceWorkerUpdate,
        PageFooter,
        PageHeader,
        SectionHeader,
        Error,
        Loader
    },

    data() {
        return {
            status: 'loading',
            scopes: {}
        }
    },

    computed: {
        langFromPath() {
            let path = window.location.pathname.substring(1).split('/')

            return path[0].length ? path[0] : null
        },
    },

    created() {
        this.$nextTick(() => {
            this.initApp(this.langFromPath ?? this.$store.getters.locale)
        })

        this.$emitter.on('localeUpdated', event => {
            this.$router.push({name: 'home', params: {lang: event.locale}})
            this.initApp(event.locale)
        })
    },


    methods: {
        initApp(locale) {
            this.status = 'loading'

            this.$api.setHeader('uuid', this.$store.getters.uuid)
            this.$api.setHeader('scope', this.$store.getters.scope)
            this.$api.setHeader('locale', locale)
            this.$i18n.loadTranslations(locale)

            setAuthHeader(this.$store.getters.token)

            this.$api.get('/init').then(data => {
                this.$nextTick(() => {
                    this.$store.dispatch('setUuid', data.uuid);
                    this.$store.dispatch('setLocale', data.locale)
                    this.$store.dispatch('setAuth', data.auth)
                    this.$api.setHeader('locale', data.locale)
                    this.$api.setHeader('uuid', data.uuid)
                })

                this.redirectToLocale(data.locale)
                this.status = 'success'
            })
        },

        redirectToLocale(locale) {
            if (this.langFromPath !== locale) {
                this.$router.push({name: 'home', params: {lang: locale}})
            }
        },
    }
}
</script>
