<template>
    <svg width="160" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M32.857 4.454 26.552 15.64h5.745l6.298-11.185h-5.738ZM40.13 4.454h5.592l1.675 8.354 11.628-8.354H65L49.44 15.64h-7.074L40.13 4.454ZM130.031 15.64h23.671l1.336-2.374h-16.136c-1.79 0-1.629-.294-1.352-.783l.745-1.322.061-.114h12.612c2.757 0 5.729-.734 7.089-3.15L160 4.456c-5.922 0-12.435-.017-18.349-.017-2.757 0-5.729.735-7.089 3.15-1.505 2.676-3.018 5.368-4.531 8.052Zm9.431-6.543.223-.392c.714-1.264 1.836-1.9 3.472-1.9h9.769l-.222.391c-.73 1.265-1.813 1.901-3.257 1.901h-9.985ZM108.802 4.454H96.206c-2.758 0-5.73.734-7.09 3.15l-4.523 8.035h5.745l4.101-7.26c.584-1.061 2.105-1.575 3.303-1.575h9.746l1.314-2.35ZM27.312 0 24.8 4.455H11.62c-2.757 0-5.73.734-7.089 3.149l-1.374 2.431L0 15.64h18.925c2.757 0 5.73-.734 7.089-3.15l1.367-2.43C29.27 6.713 31.152 3.36 33.04.015V.008L27.311 0Zm-3.833 6.804-2.757 4.887c-.584 1.06-2.105 1.575-3.303 1.575H7.081L9.84 8.379c.583-1.06 2.104-1.575 3.302-1.575H23.48ZM104.47 15.64c5.914.008 12.442 0 18.356 0 2.757 0 5.73-.735 7.089-3.15l1.913-3.394h-17.934l.222-.391c.722-1.224 1.759-1.901 3.51-1.901h15.492l1.321-2.35h-18.356c-2.758 0-5.73.734-7.089 3.15l-1.944 3.442h17.934l-.222.4c-.715 1.2-1.759 1.82-3.441 1.82h-15.515l-1.336 2.373ZM58.871 15.64h23.671l1.337-2.374H67.742c-1.813 0-1.628-.294-1.344-.783l.745-1.322.061-.114h12.612c2.757 0 5.73-.734 7.089-3.15l1.943-3.442c-5.922 0-12.435-.017-18.349-.017-2.757 0-5.73.735-7.089 3.15-1.513 2.676-3.026 5.368-4.539 8.052Zm9.432-6.543.222-.392c.715-1.264 1.836-1.9 3.472-1.9h9.77l-.223.391c-.73 1.265-1.813 1.901-3.257 1.901h-9.984Z"
              fill="#000"/>
    </svg>
</template>

<script>
export default {
    name: "Logo"
}
</script>
