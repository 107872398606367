<template>
    <div v-if="top_banner" class="xl:container xl:mx-auto">
        <img :src="top_banner.original_url" alt="" class="w-full">
    </div>

    <div class="container mx-auto mt-10">
        <div class="pb-10 grid gap-5 sm:grid-cols-2  lg:grid-cols-4 xl:gap-10">
            <product-list-item v-for="product in products"
                               :key="product.id"
                               :product="product"
                               :price-box="false"
                               @quickBuy="showQuickBuyModal"/>

            <div>
                <div class="bg-zinc-100 rounded-md w-full p-5 sm:aspect-[2/3]">
                    <router-link :to="{name: 'customer.profile', params: {lang: $store.getters.lang}}" class="flex py-3 items-center text-black hover:text-zinc-800">
                        <user-circle-icon class="h-4 w-4 mr-2"/>
                        {{ __('Profil użytkownika') }}
                    </router-link>

                    <hr>

                    <a v-if="showTerms" :href="`${toolsUrl}/b2b-terms-download`" class="flex py-3 items-center text-black hover:text-zinc-800">
                        <book-open-icon class="h-4 w-4 mr-2"/>
                        {{ __('Ogólne warunki sprzedaży') }}
                    </a>
                  
                    <template v-if="showManual">
                        <hr>
                        <a  href="#" class="flex py-3 items-center text-black hover:text-zinc-800">
                            <book-open-icon class="h-4 w-4 mr-2"/>
                            {{ __('Podręcznik użytkowania') }}
                        </a>
                    </template>

                    <div v-if="catalogues.length">
                        <hr>

                        <div class="flex py-3 items-center text-black">
                            <document-icon class="h-4 w-4 mr-2 stroke-zinc-500"/>
                            <span class="text-zinc-500">{{ __('Katalogi') }}</span>
                        </div>

                        <a v-for="(catalogue, catalogueIdx) in catalogues"
                           :key="catalogueIdx"
                           :href="catalogue.link"
                           class="flex py-2 pl-6 items-center text-zinc-900">
                            {{ catalogue.title }}
                        </a>
                    </div>

                    <div v-if="walls.length">
                        <hr>

                        <div class="flex py-3 items-center text-black">
                            <document-icon class="h-4 w-4 mr-2 stroke-zinc-500"/>
                            <span class="text-zinc-500">{{ __('Ścianki') }}</span>
                        </div>

                        <a v-for="(wall, wallIdx) in walls"
                           :key="wallIdx"
                           :href="wall.link"
                           class="flex py-2 pl-6 items-center text-zinc-900">
                            {{ wall.title }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <product-quick-buy-modal :show="quickBuyModal" :product="quickBuyProduct" :colors="quickBuyColors" @close="hideQuickBuyModal"/>
</template>

<script>
import {UserCircleIcon, BookOpenIcon, DocumentIcon} from "@heroicons/vue/outline";
import FetchDataMixin from "@/mixins/FetchDataMixin";
import {fetchData} from "@/tools/data";
import ProductListItem from "@/views/Product/components/ProductListItem";
import ProductQuickBuyModalMixin from "@/mixins/ProductQuickBuyModalMixin";
import ProductQuickBuyModal from "@/views/Product/components/ProductQuickBuyModal";

export default {
    name: 'Home',

    mixins: [
        FetchDataMixin,
        ProductQuickBuyModalMixin
    ],

    components: {
        ProductQuickBuyModal,
        ProductListItem,
        UserCircleIcon,
        BookOpenIcon,
        DocumentIcon,
    },

    data() {
        return {
            showManual: false,
            showTerms: true,
            group: null,
            top_banner: null,
            products: [],
            catalogues: [],
            walls: [],
        }
    },

    beforeRouteEnter(to, from, next) {
        fetchData('/content/home', (data, error) => next(vm => vm.setData(data, error)));
    },

    created() {
        this.$emitter.on('scopeUpdated', () => {
            fetchData('/content/home', (data, error) => this.setData(data, error))
        })
    },

    methods: {
        setData(data, error) {
            if (error) {
                this.handleError(error)
            } else {
                this.group = data.group
                this.top_banner = data.top_banner
                this.products = data.products
                this.catalogues = data.catalogues
                this.walls = data.walls
                this.setTitle(this.__('Strona główna'))
            }
        },
    }
}
</script>
