import {createRouter, createWebHistory} from 'vue-router'
import store from '@/tools/store'
import Home from '../views/Home.vue'

const routes = [
    {
        path: '/:lang([a-z]{0,2})',
        name: 'home',
        component: Home,
    },
    {
        path: '/:lang([a-z]{0,2})/auth/login',
        name: 'auth.login',
        component: () => import(/* webpackChunkName: "auth.login" */ '@/views/Auth/AuthLogin'),
        meta: { guest: true }
    },
    {
        path: '/:lang([a-z]{0,2})/auth/register',
        name: 'auth.register',
        component: () => import(/* webpackChunkName: "auth.register" */ '@/views/Auth/AuthRegister'),
        meta: { guest: true }
    },
    {
        path: '/:lang([a-z]{0,2})/auth/password-request',
        name: 'auth.password-request',
        component: () => import(/* webpackChunkName: "auth.password-request" */ '@/views/Auth/AuthPasswordRequest'),
        meta: { guest: true }
    },
    {
        path: '/:lang([a-z]{0,2})/auth/password-reset',
        name: 'auth.password-reset',
        component: () => import(/* webpackChunkName: "auth.password-reset" */ '@/views/Auth/AuthPasswordReset'),
        meta: { guest: true }
    },
    {
        path: '/:lang([a-z]{2})/c/:id([0-9]+)/:slug',
        name: 'category',
        component: () => import(/* webpackChunkName: "category" */ '@/views/Content/Category')
    },
    {
        path: '/:lang([a-z]{2})/favourites',
        name: 'favourites',
        component: () => import(/* webpackChunkName: "favourites" */ '@/views/Content/Favourites')
    },
    {
        path: '/:lang([a-z]{2})/s',
        name: 'search.result',
        component: () => import(/* webpackChunkName: "search.result" */ '@/views/Content/SearchResult')
    },
    {
        path: '/:lang([a-z]{2})/p/:id([0-9]+)/:slug',
        name: 'product.show',
        component: () => import(/* webpackChunkName: "product.show" */ '../views/Product/ProductShow'),
    },
    {
        path: '/:lang([a-z]{2})/cart',
        name: 'cart.show',
        component: () => import(/* webpackChunkName: "cart.show" */ '../views/Cart/CartShow'),
    },
    {
        path: '/:lang([a-z]{2})/order/completed/:uuid',
        name: 'order.completed',
        component: () => import(/* webpackChunkName: "order.completed" */ '../views/Order/OrderCompleted'),
    },
    {
        path: '/:lang([a-z]{2})/orders',
        name: 'order.index',
        component: () => import(/* webpackChunkName: "order.index" */ '../views/Order/OrderIndex'),
    },
    {
        path: '/:lang([a-z]{2})/order/:uuid',
        name: 'order.show',
        component: () => import(/* webpackChunkName: "order.show" */ '../views/Order/OrderShow'),
    },
    {
        path: '/:lang([a-z]{2})/customer/profile',
        name: 'customer.profile',
        component: () => import(/* webpackChunkName: "customer.profile" */ '../views/Customer/CustomerProfile'),
    },
    {
        path: '/404',
        name: 'error404',
        component: () => import(/* webpackChunkName: "error" */ '../views/Error'),
    },
    {
        path: '/:catchAll(.*)',
        redirect: '/404',
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior() {
        return { top: 0 }
    },
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.guest && store.getters.isLogged) {
        next({name: 'home', params: {lang: store.getters.lang}})
    } else if (!to.meta.guest && !store.getters.isLogged) {
        next({name: 'auth.login', params: {lang: store.getters.lang}})
    } else {
        next()
    }
})

export default router
