<template>
    <form class="mt-4" @submit.prevent="submitCartForm">
        <product-color-item v-for="color in colors"
                            :key="color.id"
                            :color="color"
                            :duplicate="duplicateAllowed"
                            :fav-products="favProducts"
                            @setColor="setColor"
                            @showDuplicateModal="showDuplicateModal"
                            @onClickFav="switchFavourite">
            <product-variant-item v-for="variant in color.variants" :key="variant.id" :variant="variant">
                <cart-item-qty v-model="variant.cart_qty" :available-stock="variant.available_stock"/>
            </product-variant-item>
        </product-color-item>

        <div class="border-t border-zinc-200 flex items-center justify-between pt-5">
            <div class="text-lg font-bold">
                {{ __('Razem') }}
            </div>

            <div class="text-lg">
                {{ cartQty }} {{ __('sztuk') }} / <span class="font-bold">{{ cartTotal }}</span> <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
            </div>
        </div>

        <base-button type="submit" class="w-full flex items-center justify-center mt-6">
            <shopping-cart-icon class="h-4 w-4 mr-2"/>
            {{ __('AKTUALIZUJ KOSZYK') }}
        </base-button>
    </form>

    <modal :show="modalDuplicate" :title="__('Duplikuj ilości produkt')" modal-class="xl:max-w-xl" @close="hideDuplicateModal">
        <p class="mb-4">
            {{ __('Wybierz kolory, do których chcesz zduplikować ilości.') }}
        </p>

        <div v-if="duplicateColorsAvailable.length > 1" class="m-3 pl-px">
            <base-checkbox v-model="duplicateSelectAll">
                {{ __('Wszystkie') }}
            </base-checkbox>
        </div>

        <div class="border border-zinc-200 rounded-md">
            <div v-for="(color, colorIdx) in duplicateColorsAvailable" :key="colorIdx" class="mx-3 py-3">
                <hr v-if="colorIdx > 0" class="-mt-3 mb-3">
                <base-checkbox v-model="duplicateSelectedColors" :value="color.id">
                    {{ color.name }}
                </base-checkbox>
            </div>
        </div>

        <div class="my-10 mx-3 pl-px">
            <base-checkbox v-model="duplicateAccept">
                {{ __('UWAGA! Wszystkie ilości w wybranych kolorach zostaną nadpisane!') }}
            </base-checkbox>
        </div>

        <div class="flex justify-end space-x-1">
            <base-button size="md" color="secondary" @click="hideDuplicateModal">
                {{ __('Anuluj') }}
            </base-button>

            <base-button size="md" color="primary" @click="duplicateExecute" :disabled="!duplicateAccept">
                {{ __('Duplikuj') }}
            </base-button>
        </div>
    </modal>
</template>

<script>
import {ShoppingCartIcon} from "@heroicons/vue/outline";
import ProductColorItem from "@/views/Product/components/ProductColorItem";
import ProductVariantItem from "@/views/Product/components/ProductVariantItem";
import CartItemQty from "@/components/CartItemQty";
import BaseButton from "@/components/BaseButton";
import Modal from "@/components/Modal";
import BaseCheckbox from "@/components/BaseCheckbox";
import {filter as _filter, mapKeys as _mapKeys} from 'lodash-es'
import FavMixin from "@/mixins/Product/FavMixin";

export default {
    name: "ProductColors",

    components: {
        BaseCheckbox,
        Modal,
        BaseButton,
        CartItemQty,
        ProductVariantItem,
        ProductColorItem,
        ShoppingCartIcon
    },

    mixins: [FavMixin],

    emits: [
        'onClickFav'
    ],
  
    props: {
        colors: {
            type: Array,
            required: true
        },
        duplicate: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {
            modalDuplicate: false,
            duplicateFromColor: null,
            duplicateSelectedColors: [],
            duplicateSelectAll: false,
            duplicateAccept: false,
            favProducts: [],
        }
    },

    computed: {
        cartQty() {
            let cartQty = 0
            this.colors.forEach(color => {
                color.variants.forEach(variant => cartQty += variant.cart_qty)
            })

            return cartQty
        },
      
        cartTotal() {
            let cartTotal = 0
            this.colors.forEach(color => {
                color.variants.forEach(variant => cartTotal += (variant.cart_qty * variant.price))
            })

            return this.price(cartTotal)
        },

        duplicateAllowed() {
            return this.duplicate && this.colors.length > 1
        },

        duplicateColorsAvailable() {
            return this.duplicateFromColor
                ? _filter(this.colors, item => item.id !== this.duplicateFromColor.id)
                : []
        },

        displayCurrency() {
            return !!this.$store.getters.customerConfig.display_currency;
        }
    },

    created() {
        this.fetchFav()
        this.$emitter.on('setProductColor', event => {
            this.setColor(event.color)
        })
    },

    watch: {
        duplicateSelectAll(value) {
            if (value) {
                this.duplicateSelectedColors = this.duplicateColorsAvailable.map(item => item.id)
            } else {
                this.duplicateSelectedColors = []
            }
        }
    },

    methods: {
        fetchFav() {
            let favProducts = JSON.parse(sessionStorage.getItem('favlist-' + this.$store.getters.scope))
            this.favProducts = ( Array.isArray(favProducts) ? favProducts : [] )
        },
        setColor(color) {
            this.colors.forEach(item => item.open = item.id === color.id)
            this.color = color
        },

        onClickFav() {
            this.$emit('onClickFav', this.product)
        },
      
        showDuplicateModal(color) {
            this.duplicateFromColor = color
            this.duplicateSelectedColors = []
            this.duplicateAccept = false
            this.modalDuplicate = true
        },

        hideDuplicateModal() {
            this.duplicateSelectAll = false
            this.duplicateFromColor = null
            this.modalDuplicate = false
        },

        duplicateExecute() {
            if (this.duplicateSelectedColors.length) {
                let variants = _filter(this.duplicateFromColor.variants, item => item.cart_qty > 0)
                let qty = _mapKeys(variants, item => item.name)
                let sizes = variants.map(item => item.name)
                let success = true
                let updated = 0

                this.colors.forEach(color => {
                    if (this.duplicateSelectedColors.includes(color.id)) {
                        color.variants.forEach(variant => {
                            if (sizes.includes(variant.name)) {
                                if (qty[variant.name].cart_qty > variant.available_stock) {
                                    variant.cart_qty = variant.available_stock
                                    success = false
                                } else {
                                    variant.cart_qty = qty[variant.name].cart_qty
                                }
                                updated += 1
                            } else {
                                variant.cart_qty = 0
                            }
                        })
                    }
                })

                if (sizes.length !== updated) {
                    success = false
                }

                if (success) {
                    this.$toastr.success(this.__('Wszystkie ilości zostały zduplikowane!'))
                } else {
                    this.$toastr.info(this.__('Nie udało się zduplikować wszystkich ilości. Sprawdź wybrane kolory.'))
                }
            }

            this.hideDuplicateModal()
        },

        submitCartForm() {
            let items = {};

            this.colors.forEach(color => {
                color.variants.forEach(variant => items[variant.id] = variant.cart_qty)
            })

            this.$api.put('/cart/items', {
                items: items
            }).then(() => {
                this.$emitter.emit('cartUpdated')
                this.$toastr.success(this.__('Koszyk został zaktualizowany!'))
            })
        },
    }
}
</script>
