<template>
    <modal :show="show" :title="__('Wczytaj XLS')" modal-class="sm:max-w-sm" @submitted="submitForm" @close="show = false">
        <div class="mb-5">
            <div v-if="xls_file">
                <div class="flex items-center justify-between bg-zinc-100 border-2 border-zinc-400 rounded-md p-3 mb-4">
                    <div class="flex items-center">
                        <document-icon class="h-5 w-5 text-zinc-400 mr-3"/>
                        <span class="text-sm text-black">
                            {{ xls_file.name }}
                        </span>
                    </div>

                    <div class="flex items-center">
                        <span class="text-sm text-zinc-400">
                            {{ xls_file.size / 1000 }} KB
                        </span>

                        <button type="button" @click="resetFile">
                            <x-icon class="h-5 w-5 text-zinc-400 ml-3"/>
                        </button>
                    </div>
                </div>

                <base-checkbox v-model="confirmed" class="border-zinc-500">
                    {{ __('UWAGA! Wczytanie polega na utworzeniu lub nadpisaniu otwartego zlecenia!') }}
                </base-checkbox>
            </div>

            <div v-show="xls_file === null"
                 class="bg-zinc-100 border border-dashed border-zinc-500 text-zinc-500 h-[106px]"
                 @dragover="onDragover"
                 @dragleave="onDragleave"
                 @drop="onDrop">
                <label for="file" class="flex flex-col items-center justify-center h-full">
                    <upload-icon class="h-8 w-8 mb-3"/>

                    <input ref="fileInput"
                           type="file"
                           id="file"
                           class="hidden"
                           accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                           @input="xls_file = $event.target.files[0]"/>

                    <span class="font-bold">
                        {{ __('Przeciągnij i upuść lub wybierz plik') }}
                    </span>
                </label>
            </div>
        </div>

        <div class="flex justify-center">
            <base-button type="submit" class="px-12" :disabled="processing || invalidForm">
                {{ __('Wczytaj') }}
            </base-button>
        </div>
    </modal>
</template>

<script>
import {XIcon} from "@heroicons/vue/solid";
import {DocumentIcon, UploadIcon} from "@heroicons/vue/outline";
import BaseButton from "@/components/BaseButton";
import BaseCheckbox from "@/components/BaseCheckbox";
import Modal from "@/components/Modal";

export default {
    name: "CartImportXlsModal",

    components: {
        Modal,
        BaseCheckbox,
        BaseButton,
        XIcon,
        DocumentIcon,
        UploadIcon,
    },

    data() {
        return {
            show: false,
            xls_file: null,
            confirmed: false,
            processing: false
        }
    },

    computed: {
        invalidForm() {
            return this.xls_file === null || this.confirmed === false
        }
    },

    created() {
        this.$emitter.on('toggleCartImportXlsModal', event => this.show = event)
    },

    methods: {
        resetFile() {
            this.xls_file = null
            this.$refs.fileInput.value = '';
            this.$refs.fileInput.type = '';
            this.$refs.fileInput.type = 'file';
        },

        onDragover(event) {
            event.preventDefault();
            event.currentTarget.classList.remove('border-zinc-500', 'bg-zinc-100');
            event.currentTarget.classList.add('border-zinc-300', 'bg-zinc-50');
        },

        onDragleave(event) {
            event.currentTarget.classList.remove('border-zinc-300', 'bg-zinc-50');
            event.currentTarget.classList.add('border-zinc-500', 'bg-zinc-100');

        },

        onDrop(event) {
            event.preventDefault();
            this.file = event.dataTransfer.files[0];
        },

        submitForm() {
            this.processing = true

            let formData = new FormData();
            formData.append('xls_file', this.xls_file);
            formData.append('confirmed', this.confirmed);

            this.$api.post( '/cart/import', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(() => {
                this.resetFile()
                this.confirmed = false
                this.show = false
                this.$toastr.success(this.__('Koszyk został zaktualizowany'))
                this.$emitter.emit('cartXlsImported')
            }).catch(() => {
                this.$toastr.error(this.__('Nie udało się załadować pliku!'))
            }).finally(() => {
                this.processing = false
            })
        }

    }
}
</script>
