<template>
    <div class="absolute inset-0 flex-1 flex flex-col bg-white">
        <div class="flex items-center h-[60px] px-3">
            <button v-if="nav.isNested" class="flex items-center p-2 -ml-2" @click="handleClickPrev">
                <chevron-left-icon class="h-6 w-6 mr-4"/>
                {{ nav.backTitle }}
            </button>
        </div>

        <div class="absolute inset-x-0 top-[60px] bottom-0 overflow-y-auto px-3" :class="{'flex flex-col justify-between': !nav.isNested}">
            <div v-if="nav.isNested" class="p-2 mb-2 text-xl">
                {{ nav.navTitle }}
            </div>

            <nav>
                <div v-for="item in nav.items" :key="item.id">
                    <button v-if="item.children.length" type="button" class="w-full flex items-center justify-between p-2" @click="handleClickNext(item)">
                        <span :class="[nav.isNested ? 'text-gray-dark' : 'text-xl']">{{ item.title }}</span>

                        <chevron-right-icon class="h-5 w-5"/>
                    </button>

                    <router-link v-else
                                 :to="{name: 'category', params: {lang: $store.getters.lang, id: item.id, slug: item.slug}}"
                                 class="flex items-center justify-between p-2"
                                 :class="[nav.isNested ? 'text-gray-dark' : 'text-xl']"
                                 @click="$emit('close')">
                        {{ item.title }}
                    </router-link>
                </div>
            </nav>

            <div v-if="!nav.isNested" class="flex-shrink-0 flex">
                <div class="-ml-1 my-5 space-y-1">
                    <router-link :to="{name: 'customer.profile', params: {lang: $store.getters.lang}}" class="flex items-center px-2 py-2 text-xl" @click="$emit('close')">
                        <user-icon class="h-6 w-6 mr-4"/>
                        {{ __('Moje Konto') }}
                    </router-link>

                    <router-link :to="{name: 'cart.show', params: {lang: $store.getters.lang}}" class="flex items-center px-2 py-2 text-xl" @click="$emit('close')">
                        <shopping-cart-icon class="h-6 w-6 mr-4"/>
                        {{ __('Koszyk') }}
                    </router-link>

                    <router-link :to="{name: 'order.index', params: {lang: $store.getters.lang}}" class="flex items-center px-2 py-2 text-xl" @click="$emit('close')">
                        <clipboard-list-icon class="h-6 w-6 mr-4"/>
                        {{ __('Historia zamówień') }}
                    </router-link>

                    <a href="#" class="flex items-center px-2 py-2 text-xl">
                        <question-mark-circle-icon class="h-6 w-6 mr-4"/>
                        {{ __('Pomoc') }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ChevronRightIcon, ChevronLeftIcon, UserIcon, ShoppingCartIcon, ClipboardListIcon, QuestionMarkCircleIcon}  from "@heroicons/vue/outline";

export default {
    name: "NavMobilePane",

    components: {
        ChevronRightIcon,
        ChevronLeftIcon,
        UserIcon,
        ShoppingCartIcon,
        ClipboardListIcon,
        QuestionMarkCircleIcon,
    },

    emits: ['close'],

    props: {
        nav: {
            type: Object,
            required: true
        },

        handleClickNext: {
            type: Function,
            default: () => {},
        },

        handleClickPrev: {
            type: Function,
            default: () => {},
        },

        handleClickClose: {
            type: Function,
            default: () => {},
        },
    }
}
</script>
