import { createApp } from 'vue'
import mitt from 'mitt';
import App from './App.vue'
import api from './tools/api'
import i18n from './tools/i18n'
import router from './tools/router'
import store from './tools/store'
import GlobalMixin from './mixins/GlobalMixin'
import './css/main.css'
import './registerServiceWorker'
import VueToastr from '@rdev/vue3-toastr';
import VueConfirm from '@rdev/vue3-confirm'
import ClickOutside from './directives/ClickOutside'

const vue = createApp(App)
vue.config.globalProperties.$emitter = mitt();
vue
    .mixin(GlobalMixin)
    .use(api)
    .use(i18n)
    .use(store)
    .use(router)
    .use(VueToastr)
    .use(VueConfirm)
    .directive('click-outside', ClickOutside)
    .mount('#app')
