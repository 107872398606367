<template>
    <div>
        <div class="w-full aspect-[2/3] relative overflow-hidden">
            <router-link :to="{name: 'product.show', params: {lang: $store.getters.lang, id: product.id, slug: product.slug}}">
                <v-lazy-image v-if="photo"
                              :src="photo.list_url"
                              alt="product.name"
                              src-placeholder="/assets/blank.png"
                              class="w-full h-full object-center object-cover"
                              @mouseenter="this.photo = product.photos.length > 1 ? product.photos[1] : product.photos[0]"
                              @mouseleave="this.photo = product.photos[0]"/>
                <v-lazy-image v-else src="/assets/blank.png" :alt="product.name" class="w-full h-full object-center object-cover"/>
            </router-link>

            <!--<button type="button" class="bg-white/[.3] rounded-full pt-8 pl-8 pr-4 pb-4 absolute -top-6 -left-6 hover:bg-white">
                <span class="sr-only">{{ __('Dodaj do ulubionych') }}</span>
                <heart-icon class="h-5 w-5"/>
            </button>-->

            <!--<div class="pl-3 pb-3 space-x-1 absolute bottom-0 inset-x-0">
                <span class="bg-zinc-200 rounded-sm text-sm px-1 py-px">Dakar</span>
                <span class="bg-zinc-200 rounded-sm text-sm px-1 py-px">Sale</span>
                <span class="bg-zinc-200 rounded-sm text-sm px-1 py-px">Regular Fit</span>
            </div>-->
        </div>

        <div class="pt-2 px-3">
            <div class="h-[52px] text-sm sm:text-lg overflow-y-hidden">
                <router-link :to="{name: 'product.show', params: {lang: $store.getters.lang, id: product.id, slug: product.slug}}" class="leading-none">
                    {{ product.name }}
                </router-link>
            </div>


            <div class="flex items-stretch justify-between mt-2" v-if="priceBox">
                <div class="text-xs leading-none">
                    <div v-if="displayRetailPrice && product.retail_price" class="text-zinc-500">
                        {{ __('RRP') }} {{ price(product.retail_price) }} <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
                    </div>

                    <div class="text-lg font-bold whitespace-nowrap">
                        {{ price(product.price) }} <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
                    </div>
                </div>
                <div class="hidden sm:inline-flex space-x-2">
                    <button class="inline-flex items-center justify-center border-2 bg-zinc-100 text-zinc-400 rounded-full px-3 py-1 ml-8 w-16 space-x-1 hover:bg-zinc-700 group hover:border-zinc-700 hover:text-white"
                            :class="[inCart ? 'border-emerald-400' : 'border-zinc-100']"
                            @click="quickBuy">
                        <check-icon class="h-5 w-5" :class="[inCart ? 'inline-block group-hover:hidden' : 'hidden']"/>
                        <plus-icon class="h-5 w-5" :class="[inCart ? 'hidden group-hover:inline-block' : 'inline-block']"/>
                        <shopping-cart-icon class="h-5 w-5" :class="[inCart ? 'hidden group-hover:inline-block' : 'inline-block']"/>
                    </button>
                    <button type="button" class="items-center justify-center border-2 bg-zinc-100 border-zinc-100 rounded-full px-1 py-1  w-8 space-x-1 hover:bg-zinc-700 group hover:border-zinc-700 hover:text-white"
                            :class="[inFav ? 'text-rose-600' : 'text-zinc-400']"
                            @click="onClickFav">
                        <solid-heart-icon v-if="inFav" class="h-5 w-5 inline-block" />
                        <heart-icon v-else class="h-5 w-5 inline-block" />
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VLazyImage from "v-lazy-image";
import {ShoppingCartIcon, PlusIcon, CheckIcon, HeartIcon} from "@heroicons/vue/outline";
import {HeartIcon as SolidHeartIcon} from "@heroicons/vue/solid";

export default {
    name: "ProductListItem",

    components: {
        VLazyImage,
        ShoppingCartIcon,
        PlusIcon,
        CheckIcon,
        HeartIcon,
        SolidHeartIcon
    },

    emits: [
        'quickBuy',
        'onClickFav'
    ],

    props: {
        product: {
            type: Object,
            required: true
        },
        cartProducts: {
            type: Array,
            default: () => []
        },
        favProducts: {
            type: Array,
            default: () => []
        },
        priceBox: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            photo: this.product.photos[0]
        }
    },

    computed: {
        inCart() {
            return this.cartProducts.includes(this.product.id)
        },

        inFav() {
            return this.favProducts.includes(this.product.id)
        },
      
        displayRetailPrice() {
            return !!this.$store.getters.customerConfig.display_retail_price;
        },

        displayCurrency() {
            return !!this.$store.getters.customerConfig.display_currency;
        }
    },

    methods: {

        onClickFav() {
            this.$emit('onClickFav', this.product)
        },
      
        quickBuy() {
            this.$emit('quickBuy', this.product)
        }
    }
}
</script>
