<template>
    <button :type="type"
            :class="[
                'border-2 leading-none rounded-md transition-colors',
                {'text-lg px-6 py-3': size === 'lg'},
                {'text-lg px-4 py-2': size === 'md'},
                {'text-sm px-4 py-2': size === 'sm'},
                {'border-transparent bg-zinc-900 text-white rounded-md hover:bg-zinc-700 disabled:bg-zinc-200 disabled:text-zinc-400': color === 'primary'},
                {'border-zinc-900 text-zinc-900 hover:border-zinc-700 hover:bg-zinc-100 disabled:bg-white disabled:border-zinc-300 disabled:text-zinc-300': color === 'secondary'},
                {'border-transparent text-zinc-900 hover:bg-zinc-50 disabled:bg-white disabled:text-zinc-300': color === 'tertiary'},
                {'cursor-not-allowed': disabled},
                {'mr-3': margin === 'mr'},
            ]"
            :disabled="disabled">
        <slot/>
    </button>
</template>

<script>
export default {
    name: "BaseButton",

    props: {
        type: {
            type: String,
            default: 'button'
        },
        disabled: {
            type: Boolean,
            default: false
        },
        color: {
            type: String,
            default: 'primary'
        },
        size: {
            type: String,
            default: 'lg'
        },
        margin: {
            type: String,
            default: ''
        }
    },
}
</script>
