<template>
    <tr class="border-t border-zinc-200">
        <td class="py-2 text-lg font-bold">
            {{ variant.name }}
        </td>
        <td class="py-2">
            <div v-if="scopeCurrent" class="text-center text-sm text-zinc-400 lg:table-cell lg:hidden">max. {{ variant.available_stock }}</div>

            <div class="flex items-stretch">
                <slot/>
            </div>
        </td>
        <td class="hidden text-right text-sm text-zinc-400 lg:table-cell">
            <span v-if="scopeCurrent">
                max. {{ variant.available_stock }}
            </span>
        </td>
        <td class="py-2 text-right">
            <div class="text-xs text-zinc-500">
                {{ price(variant.price) }} <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
            </div>
            <div class="text-lg font-bold leading-9 lg:leading-normal">
                {{ cartValue }} <span v-if="displayCurrency">{{ $store.getters.currency }}</span>
            </div>
        </td>
    </tr>
</template>

<script>
export default {
    name: "ProductVariantItem",

    props: {
        variant: {
            type: Object,
            required: true
        },
    },

    computed: {
        scopeCurrent() {
            return this.$store.getters.scope === 'current'
        },

        cartValue() {
            return this.price(this.variant.cart_qty * this.variant.price)
        },

        displayCurrency() {
            return !!this.$store.getters.customerConfig.display_currency;
        }
    }
}
</script>
