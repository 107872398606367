<template>
    <div v-click-outside="closeSearch" ref="search" class="search-wrapper">
        <div  class="search-bar" :style="searchBarStyle">
            <form @submit.prevent="submitForm" class="bg-white border border-zinc-200 rounded-full overflow-hidden pr-10 relative" @click="openSearch">
                <input ref="searchInput"
                       v-model="query"
                       type="search"
                       class="appearance-none border-0 w-full focus:outline-none focus:ring-0"
                       :placeholder="__('Szukaj...')">
                <button type="submit"
                        class="absolute top-0 right-0 rounded-full p-2 hover:bg-zinc-200 focus:bg-zinc-200">
                    <span class="sr-only">{{ __('Szukaj') }}</span>
                    <search-icon class="h-6 w-6"/>
                </button>
            </form>

            <button type="button" class="absolute top-0 right-0 rounded-full p-2 xl:right-0 hover:bg-zinc-200 focus:bg-zinc-200" @click="closeSearch">
                <x-icon class="h-6 w-6"/>
            </button>
        </div>

        <button type="button" class="p-2 rounded-full hover:bg-zinc-200 xl:hidden" @click="openSearch">
            <span class="sr-only">{{ __('Wyszukiwarka') }}</span>
            <search-icon class="h-6 w-6"/>
        </button>

        <!--<div class="search-suggestions">
            <div class="container mx-auto pb-4 lg:py-10">
                <div class="lg:grid grid-cols-4">
                    <div>
                        <div class="text-zinc-500">
                            {{ __('Sugestie') }}
                        </div>

                        <ul class="text-lg text-black my-2 space-y-1">
                            <li>Koszulka z logiem Diverse</li>
                            <li>Akcesoria na lato</li>
                            <li>EVO HEAT</li>
                            <li>Sandały</li>
                        </ul>
                    </div>

                    <div class="col-span-3">
                        <div class="grid grid-cols-2 lg:grid-cols-4 gap-8">
                            <div v-for="i in 4" :key="i">
                                <div class="w-full aspect-[2/3] relative overflow-hidden bg-neutral-200"/>

                                <div class="text-xs mt-2">
                                    Ocieplana kurtka pikowana
                                </div>

                                <div class="text-xs text-zinc-500 mt-2">
                                    {{ $store.getters.currency }} / {{ __('netto') }}
                                </div>

                                <div class="text-sm font-bold">
                                    1999,99 — 2299,99
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->
    </div>
</template>

<script>
import {SearchIcon, XIcon} from "@heroicons/vue/outline";

export default {
    name: "Search",

    components: {
        SearchIcon,
        XIcon,
    },

    data() {
        return {
            isOpen: false,
            searchBarTranslate: '',
            query: ''
        }
    },

    computed: {
        searchBarStyle() {
            return this.isOpen ? `transform: translate(-${this.searchBarTranslate}px);` : ''
        }
    },

    created() {
        this.$emitter.on('cartSummaryLoaded', () => {
            setTimeout(() => {
                this.calculateTransition()
            }, 200)
        })
    },

    mounted() {
        window.addEventListener('resize', () => {
            this.calculateTransition()
        });
        this.calculateTransition()
    },

    methods: {
        openSearch() {
            this.isOpen = true
            this.$refs.search.classList.add('open')
            this.$emitter.emit('searchToggle', {open: false})
        },

        closeSearch() {
            this.$emitter.emit('searchToggle', {open: true})
            this.$refs.search.classList.remove('open')
            this.isOpen = false
        },

        submitForm() {

            if (this.query.length) {
                this.$store.dispatch('setSearchPrevUrl', (this.$route.name == 'search.result' ? '' :  this.$route.fullPath));
                this.$router.push({name: 'search.result', params: {lang: this.$store.getters.lang}, query: {query: this.query}})
            }
        },

        calculateTransition() {
            this.searchBarTranslate = this.$refs.search.offsetLeft - (document.body.clientWidth / 2) - 300
        }
    }
}
</script>
