<template>
    <div v-show="messageText" class="text-sm text-red-500">
        {{ messageText }}
    </div>
</template>

<script>
export default {
    name: "FormError",

    props: {
        message: {
            type: [String, Array],
            default: ''
        }
    },

    computed: {
        messageText() {
            return (this.message && typeof this.message === 'object') ? this.message[0] : this.message
        }
    }
}
</script>
