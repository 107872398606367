<template>
    <slot v-if="favProducts > 0">
        <router-link :to="{name: 'favourites', params: {lang: $store.getters.lang}}"
                     class="flex items-center rounded-full group border border-zinc-100">
            <span class="sr-only">{{ __('Ulubione') }}</span>
            <div class="p-2 rounded-full group-hover:bg-zinc-100">
                <heart-icon class="h-6 w-6"/>
            </div>
            <span class="hidden sm:inline-block px-2 text-xs">{{ favProducts }}</span>
        </router-link>
    </slot> 
</template>

<script>
import {HeartIcon} from "@heroicons/vue/outline";

export default {
    name: "FavIcon",

    components: {
        HeartIcon,
    },

    data() {
        return {
            favProducts: 0
        }
    },

    created() {
        if (this.$store.getters.scope) {
            this.fetchData()
        }

        this.$emitter.on('scopeUpdated', () => {
            this.fetchData()
        })

        this.$emitter.on('favUpdated', () => {
            this.updateData()
        })
    },

    methods: {
        fetchData(){
            this.$api.get('/favourites').then(data => {
                sessionStorage.setItem('favlist-' + this.$store.getters.scope, JSON.stringify(data.favourites))
                this.favProducts = ( Array.isArray(data.favourites) ? data.favourites.length : 0 )
            })
        },      
        updateData() {
            let favProducts = JSON.parse(sessionStorage.getItem('favlist-' + this.$store.getters.scope))
            this.$api.post('/favourites', {
                favProducts: favProducts,
            }).then(this.favProducts = ( Array.isArray(favProducts) ? favProducts.length : 0 ))
        },
    }
}
</script>
