export default {
    computed: {
        apiUrl() {
            return process.env.VUE_APP_API_URL
        },
        toolsUrl() {
            return process.env.VUE_APP_TOOLS_URL
        }
    },

    methods: {
        __(key, replacements = null) {
            return this.$i18n.getTranslation(this.$store.getters.lang, key, replacements)
        },

        setTitle(title = null) {
            document.title = title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
        },

        price(value) {
            if(!value){
                return 0;
            }
            return parseFloat(value).toFixed(2)
        },
    }
}
