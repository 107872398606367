import {nextTick} from 'vue'

let i18n = {
    messages: {
        pl: {},
        en: {},
    },

    async loadTranslations(locale) {
        if (Object.keys(this.messages[locale]).length === 0) {
            const messages = await import(/* webpackChunkName: "locales-[request]" */ `../locales/${locale}.json`)
            this.messages[locale] = messages.default
        }

        return nextTick()
    },

    getTranslation(locale, key, replacements = {}) {
        let translation = this.messages[locale][key] ?? key;

        if (replacements != null) {
            Object.keys(replacements).forEach(key => {
                translation = translation.replace(':'+key, replacements[key]);
            });
        }

        return translation;
    }
}

export {i18n}

export default {
    install: (app) => {
        app.config.globalProperties.$i18n = i18n
    }
}
